import { type PropsWithChildren } from 'react'
import styles from './TextComponent.module.scss'
import classNames from 'classnames'

type TextComponentProps = {
  bold?: boolean
} & PropsWithChildren

/**
 * Body 1 by default
 */
const TextComponent = ({ bold, children }: TextComponentProps) => {
  const textComponentClassnames = classNames(styles.TextComponent, {
    [styles.Bold]: bold
  })
  return <p className={textComponentClassnames}>{children}</p>
}
export default TextComponent
